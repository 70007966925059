<template>
  <v-row no-gutters>
    <v-col cols=12 sm=6>
      <v-skeleton-loader
        v-if="loading"
        type="heading, card"
      />
      <v-card flat v-else>
        <v-card-title>
          <span class="text-h2">Game "{{ game }}"</span>
        </v-card-title>
        <v-card-text>
          <v-list-item v-for="(value, key) in gameInfo" :key="key" dense>
            <v-list-item-content>
              <v-list-item-title v-text="key" />
              <v-list-item-subtitle v-text="value" />
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols=12 sm=6>
      <v-card flat class="fill-height">
        <v-img v-if="logo"
          contain
          class="game-logo"
          max-height="250px"
          :src="logo"
        />
        <v-sheet v-else
          class="d-flex game-logo"
          color="background"
          elevation=12
          width="100%"
        >
          <span class="h2 ma-auto secondary--text">
            {{ labels.missingLogo }}
          </span>
        </v-sheet>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="!showLauncher"
            color="primary"
            elevation=2
            outlined
            :dark="showLauncher"
            :href="gameLaunch"
            target="_blank"
            class="text-decoration-none"
          >
            <v-icon left dark v-text="'$gameServer'" />
            {{ labels.play }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-mixed-operators */
import { get, isEmpty } from 'lodash';
import { mapState } from 'vuex';
import { labels } from '@/assets/texts.json';
import { fetchData, getMany } from '@/helpers';

export default {
  name: 'gameInfo',
  props: {
    game: {
      type: String,
      required: true,
      default: '',
    },
    provider: {
      type: [Object, null],
      required: true,
      default: () => {},
    },
    gameInstances: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState('fetch', [
      'environment',
    ]),
    gameInfo() {
      return {
        Environment: this.environment.core_version
          ? `${this.environment.name} (core ${this.environment.core_version})`
          : this.environment.name,
        Provider: get(this.provider, 'name'),
        'Installed backend versions': (
          !isEmpty(this.gameInstances)
          && getMany(
            this.gameInstances.map((gi) => get(gi, 'game_backend_version')),
            3,
          ) || this.labels.notInstalled
        ),
        'Installed Frontend versions': (
          !isEmpty(this.gameInstances)
          && getMany(
            this.gameInstances.map(
              (gi) => get(gi, 'frontend_version.version'),
            ),
            3,
          ) || this.labels.notInstalled
        ),
      };
    },
    gameLaunch() {
      const launcher = get(this.environment, 'launcher');
      if (!launcher) return '#';
      const url = new URL(`${launcher.server}/${launcher.path}`);
      url.searchParams.append('lang', get(launcher, 'lang'));
      url.searchParams.append('gameUid', this.game.toUpperCase());
      url.searchParams.append('casonoUid', get(launcher, 'casino'));
      //  Add optional customisable querystring, e.g.:
      //   "?canBeForced=true&currencyCode=EUR"
      if (launcher.get('qs')) url.searchParams.append('qs', launcher.qs);
      return url.href;
    },
    showLauncher() {
      // Show if there's a launcher or if we've an installed version+frontend
      return this.launcher && this.gameInstances && this.gameInstances.some(
        (gi) => !!get(gi, 'frontend_version'),
      );
    },
  },
  data: () => ({
    labels,
    logo: null,
  }),
  methods: {
    fetchLogo() { // leave as is
      return fetchData({
        url: '/logo/',
        params: {
          provider: get(this.provider, 'code'),
          frontend: this.game.toUpperCase(),
        },
      }).then((response) => {
        this.logo = response.logo ? `/${response.logo}` : null;
      }).catch((err) => {
        // 404 is normal for frontends, in fact all onmix games will throw a
        // "Not found" error here
        if (err.response.status !== 404) {
          throw err;
        }
      });
    },

  },
};
</script>

<style scoped>
  .game-logo {
    min-height: 85%;
  }

</style>
